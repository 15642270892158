import { bindEvent } from './helpers'

interface TrackParamsType {
  mediaId: string
  playBtn: Element | null
  stickyBtn: Element | null
}

export default function ({ mediaId, playBtn, stickyBtn }: TrackParamsType) {
  const track = (window as any).cts
  if (!(track && track.send && track.send.event)) {
    return
  }

  bindEvent(playBtn, 'click', () => {
    track.send.event('action', 'click', `shoppable-video|${mediaId}|play`)
  })

  bindEvent(stickyBtn, 'click', () => {
    track.send.event('action', 'click', `shoppable-video|${mediaId}|sticky-button`)
  })
}

export function onIframeCTSEvent(data: any) {
  const {action, label} = data

  const sendEvent = (window as any).cts?.send?.event
  if (typeof sendEvent !== 'function') {
    return
  }

  sendEvent('action', action, label)
}

export function onIframeJWPlayerCTSEvent(payload: any) {
  const {type, detail} = JSON.parse(payload.data)

  window.dispatchEvent(new CustomEvent(type, {detail}))
}
