import { initPayloadType } from '../types'
import { bindEvent, sendMessageInit, scrollLock } from './helpers'
import tracking, { onIframeCTSEvent, onIframeJWPlayerCTSEvent } from './tracking'
import render from './render'
import './global.scss'
import './styles.scss'
;(function () {
  const rootEls = document.querySelectorAll('.liveShopping-root')
  Array.from(rootEls).map((root) => {
    if (!root || root.classList.contains('liveShopping-initialised')) {
      return
    }

    root.classList.add('liveShopping-initialised')
    ;(root as any).style.maxHeight = '580px'
    ;(root as any).style.position = 'relative'

    let aspectPortrait = true
    const mediaId = root.getAttribute('data-mediaId')
    const cssUrl = 'https://aller-live-shopping.vercel.app/scripts/embed'
    const iframeUrl = 'https://aller-live-shopping.vercel.app'
    // const cssUrl = './dist'
    // const iframeUrl = 'http://localhost:3000'

    render(
      {
        cssSrc: `${cssUrl}/bundle.css`,
        description: '',
        iframeSrc: `${iframeUrl}/${mediaId}/`,
        notificationText: 'Shoppable video',
        posterSrc: `https://cdn.jwplayer.com/v2/media/${mediaId}/poster.jpg?width=720`,
        stickyImgSrc: `https://cdn.jwplayer.com/v2/media/${mediaId}/poster.jpg?width=320`,
        stickyText: 'Missa inte!',
        title: '',
        videoSrc: `https://cdn.jwplayer.com/v2/media/${mediaId}/poster.mp4`,
      },
      root,
    )

    const playBtn = root.querySelector('.js-liveShopping-playBtn')
    const closeBtn = root.querySelector('.js-liveShopping-closeBtn')
    const closeArea = root.querySelector('.liveShopping-modal-closeArea')
    const iframe = root.querySelector('.liveShopping-iframe')
    const modal = root.querySelector('.liveShopping-modal')
    const stickyArea = root.querySelector('.liveShopping-stickyArea')
    const heading = root.querySelector('.liveShopping-heading')
    const description = root.querySelector('.liveShopping-description')
    const notificationText = root.querySelector('.liveShopping-notificationText')
    const stickyText = root.querySelector('.liveShopping-stickyArea-text')
    const stickyHeading = root.querySelector('.liveShopping-stickyArea-heading')

    tracking({ mediaId: mediaId || '', playBtn, stickyBtn: stickyArea })

    const sendMessage = sendMessageInit(iframe as HTMLIFrameElement, iframeUrl)

    bindEvent(window, 'message', (e) => {
      if (![iframeUrl].includes(e.origin)) {
        // console.error('parent: error disallowed origin: ', e.origin)
        return
      }

      const action = e.data?.action || ''
      const payload = e.data?.payload || {}
      // const rOrigin = e.data?.receivedOrigin || ''
      // const origin = e.data?.origin || ''
      actionHandler(action, payload, e)
    })

    bindEvent(playBtn, 'click', onModalOpen)
    bindEvent(stickyArea, 'click', onModalOpen)
    bindEvent(closeBtn, 'click', onModalClose)
    // bindEvent(closeArea, 'click', onModalClose)

    let isSticky = false
    let viewed = false
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.isIntersecting) {
          viewed = true
        }

        if (e.intersectionRatio < 1) {
          if (isSticky) {
            root.classList.remove('liveShopping-isSticky')
            isSticky = false
          }

          if (viewed && !e.isIntersecting) {
            root.classList.add('liveShopping-isSticky')
            isSticky = true
          }
        }
      },
      { threshold: [0.1] },
    )

    observer.observe(root)

    bindEvent(window, 'resize', onResize)

    function onResize() {
      setFrameHeight()
    }
    onResize()

    function setFrameHeight() {
      if (!modal) {
        return
      }

      const height = window.innerHeight

      const elementWidth = aspectPortrait ? 500 : 694 // 9 : 16
      const elementHeight = aspectPortrait ? 940 : 902

      const newWidth = (height / elementHeight) * elementWidth

      if (height < elementHeight && window.innerWidth > 699) {
        ;(modal as any).style.maxWidth = newWidth + 'px'
      }
    }

    function updateContent(data: { app: { [key: string]: any }; media: { [key: string]: any } }) {
      if (!data) {
        return
      }

      if (data.app.notificationDot) {
        root?.classList.add('liveShopping-enableNotificationIcon')
      }

      if (heading && mediaId === data?.media?.id) {
        heading.textContent = data.app?.title || data.media.title
      }

      if (description && mediaId === data?.media?.id) {
        description.textContent = data.app?.description || data.media.description
      }
      if (notificationText && data.app?.notificationText && mediaId === data?.media?.id) {
        notificationText.textContent = data.app?.notificationText
      }
      if (stickyHeading && mediaId === data?.media?.id) {
        stickyHeading.textContent = data.app?.title || data.media.title
      }
      if (stickyText && data.app?.stickyText && mediaId === data?.media?.id) {
        stickyText.textContent = data.app?.stickyText
      }
    }

    function onModalClose() {
      setFullscreen(false)
      sendMessage({ action: 'liveShoppingIframe-close', payload: {} })
    }

    function onModalOpen() {
      setFullscreen(true)
      sendMessage({ action: 'liveShoppingIframe-open', payload: {} })
      onResize()
    }

    function setFullscreen(state = false) {
      if (!root) {
        return
      }

      if (state && !root.classList.contains('modal-isVisible')) {
        root.classList.add('modal-isVisible')
        scrollLock(true)
      } else {
        root.classList.remove('modal-isVisible')
        scrollLock(false)
      }
    }

    function setAspectRatio(isPortrait: boolean) {
      if (!root) {
        return
      }

      aspectPortrait = isPortrait

      if (isPortrait) {
        root.classList.add('liveShopping-aspectRatio-portrait')
      } else {
        root.classList.remove('liveShopping-aspectRatio-portrait')
      }
    }

    function onFrameInit(data: initPayloadType) {
      const { aspectRatio } = data.media || {}
      if (!root) {
        return
      }

      root.classList.add('liveShopping-jwInitialised')

      if (aspectRatio) {
        setAspectRatio(aspectRatio === '9:16')
      }

      updateContent(data)
      onResize()
    }

    function onCTSEvent (payload: any, handler: Function) {
      const {mediaId: eventMediaId} = payload;
      if (eventMediaId != mediaId) {
        return
      }
      handler(payload);
    }

    function actionHandler(action: string, payload = {} as any, e: any) {
      switch (action) {
        case 'liveShopping-init':
          onFrameInit(payload)
          return
        case 'jw-ready':
          // console.log('jw ready')
          return
        case 'liveShopping-cts':
          onCTSEvent(payload, onIframeCTSEvent);
          return
        case 'liveShopping-cts-jwplayer':
          // onCTSEvent(payload, onIframeJWPlayerCTSEvent);
          return
        default:
          console.log('error: action not defined')
          return
      }
    }
  })
})()
