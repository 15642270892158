import { renderDataType } from '../types'
import { createEl } from './helpers'

export function createElements({
  cssSrc,
  videoSrc,
  posterSrc,
  stickyImgSrc,
  title,
  description,
  stickyText,
  notificationText,
  iframeSrc,
}: renderDataType) {
  const createVideoElement = (type: string, attributes: any[]) => {
    const el = document.createElement(type)

    if (attributes) {
      attributes.forEach(([k, v]) => {
        el.setAttribute(k, v)
        ;(el as any)[k] = v
      })
    }
    return el
  }

  const cssLink = createEl('link', [
    ['rel', 'stylesheet'],
    ['type', 'text/css'],
    ['href', cssSrc],
  ])

  const video = createVideoElement('video', [
    ['class', 'liveShopping-media'],
    ['src', `${videoSrc}?width=640`],
    ['poster', posterSrc],
    ['autoplay', true],
    ['loop', true],
    ['muted', true],
    ['playsinline', true],
  ])

  const playIconArea = createEl(
    'svg',
    [['class', 'liveShopping-playIconArea']],
    '<svg class="liveShopping-playIcon" style="max-width: 90px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false" > <path d="M23.3794 12.0647C23.3794 5.81676 18.3126 0.75 12.0647 0.75C5.81676 0.75 0.75 5.81676 0.75 12.0647C0.75 18.3126 5.81676 23.3794 12.0647 23.3794C18.3126 23.3794 23.3794 18.3126 23.3794 12.0647Z" fill="currentColor"></path> <path d="M17.1382 11.1499L9.40795 6.39865C9.1958 6.27047 8.91735 6.26163 8.70078 6.38539C8.47979 6.50914 8.34277 6.74339 8.34277 6.99532V16.4537C8.34277 16.7056 8.47979 16.9399 8.69636 17.0636C8.80243 17.1211 8.91735 17.152 9.03668 17.152C9.16486 17.152 9.28861 17.1167 9.39911 17.0504L17.1293 12.3389C17.3371 12.2151 17.4652 11.9853 17.4652 11.7466C17.4741 11.5035 17.3459 11.2737 17.1382 11.1499Z" fill="white"></path>',
  )

  const notificationIcon = createEl(
    'span',
    [['class', 'liveShopping-notification-icon']],
    createEl('span'),
  )

  const notificationTextSpan = createEl(
    'span',
    [['class', 'liveShopping-notificationText']],
    notificationText,
  )

  const notification = createEl(
    'div',
    [['class', 'liveShopping-notification']],
    [notificationIcon, notificationTextSpan],
  )
  const contentHeading = createEl('h3', [['class', 'liveShopping-heading']], title)
  const contentDesc = createEl('p', [['class', 'liveShopping-description']], description)
  const contentArea = createEl(
    'div',
    [['class', 'liveShopping-contentArea']],
    [contentHeading, contentDesc],
  )

  const container = createEl(
    'div',
    [
      ['class', 'liveShopping-container js-liveShopping-playBtn'],
      ['role', 'button'],
    ],
    [video, playIconArea, notification, contentArea],
  )

  const closeArea = createEl('div', [
    ['class', 'liveShopping-modal-closeArea'],
    ['role', 'button'],
  ])

  const iframe = createEl('iframe', [
    ['class', 'liveShopping-iframe'],
    ['src', iframeSrc],
  ])

  const iframeArea = createEl('div', [['class', 'liveShopping-iframeArea']], iframe)

  const modalArea = createEl('div', [['class', 'liveShopping-modal']], iframeArea)
  const closeBtn = createEl('button', [
    ['class', 'liveShopping-closeBtn js-liveShopping-closeBtn'],
    ['type', 'button'],
  ])

  const stickyImg = createEl('img', [
    ['class', 'liveShopping-stickyArea-media'],
    ['src', stickyImgSrc],
  ])

  const stickyHeading = createEl('h3', [['class', 'liveShopping-stickyArea-heading']], title)

  const stickyDesc = createEl('p', [['class', 'liveShopping-stickyArea-text']], stickyText)

  const stickyContent = createEl(
    'div',
    [['class', 'liveShopping-stickyArea-content']],
    [stickyHeading, stickyDesc],
  )

  const stickyNotificationIcon = createEl(
    'span',
    [['class', 'liveShopping-notification-icon liveShopping-notification-icon--sticky']],
    createEl('span'),
  )

  const stickyArea = createEl(
    'div',
    [
      ['class', 'liveShopping-stickyArea'],
      ['role', 'button'],
    ],
    [stickyImg, stickyContent, stickyNotificationIcon],
  )

  const style = createEl(
    'style',
    [['class', 'liveShopping-intro']],
    '.liveShopping-intro{width:100%;max-width:100%;height:580px;position:absolute;top:0;left:0;z-index:2;background-color:#000;transition:opacity 0.3s 0.2s ease;pointer-events:none;}.liveShopping-root:{overflow:hidden;}',
  )

  const introArea = createEl('div', [['class', 'liveShopping-intro']])

  return [cssLink, style, introArea, container, closeArea, closeBtn, modalArea, stickyArea]
}

export default async function render(data: renderDataType, element: Element) {
  const els = createElements(data)
  els.forEach((el) => {
    element.appendChild(el)
  })

  return element
}
