export function sendMessageInit(iframe: HTMLIFrameElement, origin = '*') {
  if (!iframe) {
    console.error('sendMessageInit: iframe not found')
    return () => null
  }

  return function sendMessage(data: { [key: string]: any }) {
    // if (typeof window !== 'undefined' && data && origin) {

    // TEMP dev purpose
    const msgWindow = (iframe as HTMLIFrameElement)?.contentWindow

    if (msgWindow?.postMessage && data) {
      msgWindow?.postMessage(data, origin)
      // msgWindow.postMessage(data, origin)
    } else {
      console.error('error sending message', data, origin)
    }
  }
}

export function bindEvent(el: Element | Window | null, event: string, cb: (...arg: any) => void) {
  if (el) {
    el.addEventListener(event, cb, false)
  }
}

export function scrollLock(state = false) {
  if (state) {
    document.body.style.overflow = 'hidden'
    document.body.style.height = '100vh'
  } else {
    document.body.style.overflow = 'visible'
    document.body.style.height = 'auto'
  }
}

export const fetcher = (url: string) =>
  fetch(url, {
    method: 'GET',
  })
    .then(async (res) => {
      if (res.status === 200) {
        const data = await res.json()
        return {
          status: res.status,
          data,
        }
      }

      return {
        status: res.status,
        error: 'error furfilling fetch request',
        data: {},
      }
    })
    .catch((err) => err)

export function createEl(
  type: string,
  attributes?: [string, string][],
  children?: Element[] | Element | string,
  textContent = '',
): Element {
  const el = document.createElement(type)
  if (attributes) {
    attributes.forEach(([k, v]) => {
      el.setAttribute(k, v)
    })
  }

  if (children) {
    if (Array.isArray(children)) {
      children?.forEach((c) => {
        el.appendChild(c)
      })
    } else if (typeof children === 'string') {
      el.innerHTML = children
    } else {
      el.appendChild(children)
    }
  }

  if (textContent) {
    el.textContent = textContent
  }

  return el
}
